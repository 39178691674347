import { css } from '@emotion/core';
import { createMuiTheme } from '@material-ui/core/styles';

export const Breakpoints = {
  sm: (...args) => css`
    @media (min-width: 48rem) {
      ${css(...args)};
    }
  `,
  md: (...args) => css`
    @media (min-width: 62rem) {
      ${css(...args)};
    }
  `,
  lg: (...args) => css`
    @media (min-width: 75rem) {
      ${css(...args)};
    }
  `,
};

export const Globals = {
  green: '#64A70B',
  lemon: '#f0aa00',
  ripelemon: '#e6a50a',

  lightgrey: '#959595',
  gray: '#5b5b5b',
  grayX: '#3e3838',
  darkgrey: '#3f3d37',
  white: '#f9f9f9',
  pureWhite: '#ffffff',
  cream: '#f5f5f5',

  purple: '#440099',
  blue: '#440099',
  blueX: '#590fb5',
  yellow: '#FFAD00',
  yellowX: '#e09a04',
  orange: '#FF5000',
  red: '#F9423A',

  grayscale_d3: '#f9f9f9',
  grayscale_d2: '#f5f5f5',
  grayscale_d1: '#959595',
  grayscale_b: '#5b5b5b',
  grayscale_u1: '#3f3d37',
  grayscale_u2: '#000000',
};

export const FontSizes = {
  d2: '0.75rem',
  d1: '0.875rem',
  b: '1.3125rem',
  u1: '1.5rem',
  u2: '2.25rem',
  u3: '3rem',
  u4: '4.5rem',
};

export const muiTheme = createMuiTheme();
