import * as React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import { Globals, Breakpoints } from '../styles/global';
import { ColorStrip } from './color-strip';
import { Box } from '@material-ui/core';
import { Twitter } from '@material-ui/icons';

const Footer: React.SFC = ({
  data: {
    allMarkdownRemark: {
      edges: [
        {
          node: { frontmatter },
        },
      ],
    },
  },
}) => {
  const year = new Date().getFullYear();
  return (
    <FooterWrap>
      <Grid>
        <Left>
          <div>
            <Logo src="/logo.png" alt="Citrus Logo" />
          </div>

          <SocialLinks>
            <a
              href="https://www.instagram.com/citrusfcn"
              target="_blank"
              className="p-d1"
            >
              <InstagramIcon />
              <span className="sr-only">Citrus FCN on Instagram</span>
            </a>
            <a
              href="https://www.facebook.com/CitrusFCN/"
              target="_blank"
              className="p-d1"
            >
              <FacebookIcon />
              <span className="sr-only">Citrus FCN on Facebook</span>
            </a>
            <a
              href="https://www.twitter.com/citrusfcn/"
              target="_blank"
              className="p-d1"
            >
              <TwitterIcon />
              <span className="sr-only">Citrus FCN on Twitter</span>
            </a>
          </SocialLinks>
        </Left>
        <Ul className="font-b">
          <li>
            <a href="https://citrushealth.org/careers" className="p-d1">
              Careers
            </a>
          </li>
          <li>
            <a className="p-d1" href="/about/florida-statute-compliance/">
              409.998 Compliance
            </a>
          </li>
          <li>
            <Link to="/contact" className="p-d1">
              Contact Us
            </Link>
          </li>
          <li>
            <Link to="/privacy" className="p-d1">
              Privacy Policy
            </Link>
          </li>
          <li>
            <Link to="/COVID19" className="p-d1">
              COVID19
            </Link>
          </li>
        </Ul>

        <Right>
          <Address className="font-u1">
            <span>{frontmatter.address}</span>
            <span>{frontmatter.address2}</span>
            <span>
              {frontmatter.citystate}, {frontmatter.zipcode}
            </span>
            <span>{frontmatter.phone}</span>
          </Address>
          <p className="text-center font-d1">
            © {year} CITRUS HEALTH NETWORK INC. ALL RIGHTS RESERVED
          </p>
          <a
            href="https://www.myflfamilies.com/"
            target="_blank"
            className="align-center py-b"
          >
            <img
              src="/dcf-logo.png"
              alt="Department of Children and Families logo"
              className="mr-u2"
            />
            <span className="sr-only">
              Florida Department of Children and Families
            </span>
          </a>
        </Right>
      </Grid>
      <ColorStrip />
    </FooterWrap>
  );
};

const Grid = styled.div`
  max-width: 75rem;
  width: 100%;
  display: flex;
  padding: 2rem 2rem;

  ${Breakpoints.sm`

    > div, > ul {
      width: 33%;
    }
  `}
`;

const SocialLinks = styled.div`
  margin-left: 2rem;
  margin-top: 1rem;
  a {
    color: white;
    &: hover {
      color: rgba(200, 200, 200, 1);
    }
    box-shadow: 0.0125rem 0.0125rem 0.125rem rgba(0, 0, 0, 0.1);
    border-radius: 2rem;
    margin-right: 2rem;
    margin-bottom: 0.5rem;
    height: 2.5rem;
    width: 2.5rem;
    display: block;
    svg {
      font-size: 2rem;
    }
  }
`;

const Left = styled.div`
  position: relative;
  display: flex;
  ${Breakpoints.sm`
    &::after {
      content: '';
      width: 0.0125rem;
      background-color: white;
      position: absolute;
      right: 0;
      bottom: 4rem;
      top: 2rem;
    }
    
  `}
`;
const Right = styled.div`
  text-align: center;
  a,
  & img {
    margin: 0 auto;
  }
`;

const Logo = styled.img`
  display: block;
  max-width: 12rem;
`;
const Ul = styled.ul`
  li {
    list-style-type: none;
  }
  a {
    color: ${Globals.white};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const FooterWrap = styled(Box)`
  display: flex;
  background-color: ${Globals.grayscale_u1};
  color: ${Globals.white};
  justify-content: center;
  flex-wrap: wrap;
  hr {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 0;
    border-color: ${Globals.grayscale_d2};
  }
  footer img {
    max-width: 8rem;
    position: relative;
    top: -1rem;
  }
`;

const Address = styled.address`
  > span {
    display: block;
    font-style: normal;
  }
`;

export default () => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "footer" } } }
        ) {
          edges {
            node {
              id
              frontmatter {
                address
                address2
                citystate
                zipcode
                phone
              }
            }
          }
        }
      }
    `}
    render={data => <Footer data={data} />}
  />
);
