import * as React from 'react';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/icons/Menu';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import Phone from '@material-ui/icons/Phone';
import Facebook from '@material-ui/icons/Facebook';
import { Link, StaticQuery, graphql, navigate } from 'gatsby';
import { getOptions, OptionDropdown } from './header-utils';
import {
  DesktopUtilityWrap,
  DesktopNavWrap,
  DesktopNav,
  DesktopUtility,
  DesktopLogo,
  Right,
  HeaderWrap,
  Logo,
  PaddedDrawer,
  Nav,
  Bottom,
  BottomWrap,
  Search,
  SearchWrap,
} from './header-styles';

interface HeaderState {
  left: boolean;
  searchOpen: boolean;
  query: string;
  query2: string;
  open1: boolean;
  open2: boolean;
}

interface EdgeNode {
  node: { frontmatter: { phone: string } };
}

interface PropData {
  allMarkdownRemark: {
    edges: EdgeNode[];
  };
}

interface Props {
  data: PropData;
}

const Header: React.FC<Props & { children?: React.ReactNode }> = props => {
  const {
    data: {
      allMarkdownRemark: {
        edges: [
          {
            node: { frontmatter },
          },
        ],
      },
    },
  } = props;

  const phone = frontmatter.phone;
  const [state, setState] = React.useState<HeaderState>({
    left: false,
    searchOpen: false,
    query: '',
    query2: '',
    open1: null,
    anchor2: null,
  });

  const toggleDrawer = (open: boolean, drawer: 'left' | 'search' = 'left') => (
    event: React.KeyboardEvent | React.MouseEvent<HTMLButtonElement>,
  ) => {
    const key = (event as React.KeyboardEvent).key;
    if (event.type === 'keydown' && (key === 'Tab' || key === 'Shift')) {
      return;
    }
    if (drawer === 'left') {
      setState({ ...state, left: open, searchOpen: false });
    } else if (drawer === 'search') {
      setState({ ...state, searchOpen: open, left: false });
    }
  };

  const { left, searchOpen, query, query2, open1, anchor2 } = state;

  const options1 = getOptions(query);
  const options2 = getOptions(query2);

  const sideList = () => (
    <Nav role="presentation" onKeyDown={toggleDrawer(false)}>
      <List>
        <ListItem>
          <Link to="/about">
            <ListItemText primary="About" />
          </Link>
        </ListItem>
        <ListItem>
          <Link to="/fostering/">
            <ListItemText primary="Fostering" />
          </Link>
        </ListItem>
        <ListItem>
          <Link to="/adopting/">
            <ListItemText primary="Adopting" />
          </Link>
        </ListItem>

        <ListItem>
          <Link to="/system-of-care/">
            <ListItemText primary="Our System of Care" />
          </Link>
        </ListItem>
        <ListItem>
          <Link to="/news-events">
            <ListItemText primary="News & events" />
          </Link>
        </ListItem>
      </List>
      <Divider />
      <BottomWrap>
        <Bottom>
          <List>
            <ListItem>
              <a href="http://citrushealth.org" target="_blank">
                <ListItemText primary="CitrusHealth.org" />
              </a>
            </ListItem>
            <ListItem>
              <a href="http//citrushealth.org/staff" target="_blank">
                <ListItemText primary="Staff Resources" />
              </a>
            </ListItem>
            <ListItem>
              <Link to="/contact">
                <ListItemText primary="Contact Us" />
              </Link>
            </ListItem>
          </List>
        </Bottom>
      </BottomWrap>
    </Nav>
  );

  return (
    <HeaderWrap className="space-between align-center wrap header-color-background">
      <Logo className="mobile-only-flex">
        <Link to="/" className="block">
          <span className="sr-only">Citrus Family Health Network - home</span>
          <img src="/logo.png" alt="Citrus Family Health Network" />
        </Link>
      </Logo>
      <div className="mobile-only-flex align-center">
        <a href={`tel:${phone}`} className="phone-link">
          <span className="sr-only">Call Citrus</span>
          <Phone />
        </a>
        <Button
          onClick={toggleDrawer(true, 'search')}
          className="mobile-only ml-u1 white"
        >
          <span className="sr-only">Search</span>
          <SearchIcon />
        </Button>

        <Button onClick={toggleDrawer(true)} className="mobile-only white">
          <span className="sr-only">Open Left</span>
          <Menu />
        </Button>
      </div>

      <PaddedDrawer
        anchor="top"
        open={searchOpen}
        onClose={toggleDrawer(false, 'search')}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        auto complete goes here
      </PaddedDrawer>
      <PaddedDrawer
        open={left}
        onClose={toggleDrawer(false)}
        className="mobile-only"
        anchor="top"
      >
        {sideList()}
      </PaddedDrawer>
      <DesktopUtilityWrap>
        <DesktopUtility py={1}>
          <Right px="2rem">
            <a href="http://citrushealth.org" target="_blank" className="mr-b">
              Citrus Health Network
            </a>
            <a
              href="http://citrushealth.org/staff"
              target="_blank"
              className="mr-b"
            >
              Staff Resources
            </a>

            <Link to="/COVID19" className="mr-b">
              COVID19
            </Link>

            <a
              href="https://www.facebook.com/CitrusFCN/"
              target="_blank"
              className="mr-b"
            >
              <span className="sr-only">Facebook</span>
              <Facebook />
            </a>
            <SearchWrap
              autoComplete="off"
              onSubmit={e => {
                e.preventDefault();
                setState({
                  ...state,
                  query: '',
                  open1: false,
                });
                return options1.length > 0
                  ? navigate(options1[0].url)
                  : navigate('/');
              }}
            >
              <Search
                id="combo-box-demo"
                onFocus={() => {
                  if (!open1) {
                    setState({
                      ...state,
                      open1: true,
                    });
                  }
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setState({
                    ...state,
                    query: e.target.value,
                    open1: true,
                  });
                }}
                value={state.query}
                label="Search"
                variant="outlined"
              />
              {open1 && options1.length > 0 && (
                <OptionDropdown
                  options={options1}
                  onClose={() => {
                    setState({ ...state, open1: false });
                  }}
                />
              )}
            </SearchWrap>
          </Right>
        </DesktopUtility>
      </DesktopUtilityWrap>
      <DesktopNavWrap className="header-color-background">
        <DesktopNav>
          <DesktopLogo>
            <Link to="/" className="block">
              <span className="sr-only">
                Citrus Family Health Network - home
              </span>
              <img src="/logo.png" alt="Citrus Family Health Network" />
            </Link>
          </DesktopLogo>

          <ul className="main">
            <li>
              <Link to="/about" className="serif uppercase pb-d3 orange">
                <ListItemText primary="About" />
              </Link>
            </li>
            <li>
              <Link to="/fostering/" className="serif uppercase pb-d3 red">
                <ListItemText primary="Fostering" />
              </Link>
            </li>

            <li>
              <Link to="/adopting/" className="serif uppercase pb-d3 red">
                <ListItemText primary="Adopting" />
              </Link>
            </li>

            <li>
              <Link to="/system-of-care/" className="serif uppercase pb-d3 red">
                <ListItemText primary="Our System of Care" />
              </Link>
            </li>
            <li>
              <Link to="/news-events" className="serif uppercase pb-d3 orange">
                <ListItemText primary="News and Events" />
              </Link>
            </li>
          </ul>
        </DesktopNav>
      </DesktopNavWrap>
    </HeaderWrap>
  );
};

export default () => (
  <StaticQuery
    query={graphql`
      query HeaderQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "footer" } } }
        ) {
          edges {
            node {
              id
              frontmatter {
                phone
              }
            }
          }
        }
      }
    `}
    render={(data: PropData) => <Header data={data} />}
  />
);
