import React from 'react';
import styled from '@emotion/styled';
import { Globals } from '../styles/global';
import { Box } from '@material-ui/core';

export const ColorStrip = ({ height }: { height?: string }) => {
  return (
    <Wrapper aria-hidden="true" height={height ? height : '3rem'}>
      <Purple />
      <Orange />
      <Green />
      <Yellow />
      <BloodOrange />
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  display: flex;
  width: 100%;
  > div {
    height: 100%;
    flex-shrink: 0;
    flex-grow: 1;
    flex-basis: 1;
  }
`;
const Purple = styled.div`
  background-color: ${Globals.purple};
`;
const Green = styled.div`
  background-color: ${Globals.green};
`;
const Orange = styled.div`
  background-color: ${Globals.orange};
`;
const Yellow = styled.div`
  background-color: ${Globals.yellow};
`;
const BloodOrange = styled.div`
  background-color: ${Globals.red};
`;
