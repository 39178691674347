import { Breakpoints, Globals } from '../styles/global';
import { withStyles } from '@material-ui/core/styles';
import styled from '@emotion/styled';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

export const Search = withStyles({
  root: {
    '& .MuiFormLabel-root': {
      color: 'white',
    },
    '& .MuiOutlinedInput-root': {
      color: 'white',
      '& fieldset, & fieldset:hover': {
        borderColor: 'white',
        borderWidth: '0.0625rem',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(255,255,255,0.9)',
      },
    },
  },
})(TextField);

export const SearchWrap = styled.form`
  position: relative;
`;

export const PaddedDrawer = withStyles({
  paper: {
    paddingTop: '6rem',
  },
})(Drawer);

export const DesktopLogo = styled.div`
  padding-left: 2rem;
  img {
    width: 10rem;
    position: relative;
    top: -0.5rem;
    height: auto;
  }
`;

export const HeaderWrap = styled.div`
  a {
    text-decoration: none;
    display: flex;
    &.block {
      display: block;
    }
  }
  .phone-link,
  .white {
    color: ${Globals.white};
  }
  position: relative;
  z-index: 1301;
  ${Breakpoints.sm`
    position: static;
  `}
`;
export const DesktopUtilityWrap = styled.div`
  display: none;
  justify-content: center;
  width: 100%;
  flex-shrink: 0;
  background-color: ${Globals.purple};
  ${Breakpoints.sm`
    display: flex;
  `};
`;

export const DesktopUtility = styled(Box)`
  background-color: ${Globals.purple};
  color: ${Globals.white};
  width: calc(100% - 4rem);
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  max-width: 75rem;
`;

export const Right = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  a {
    color: white;
    &:hover {
      text-decoration: underline;
    }
  }
  ul.autocomplete {
    padding: 1rem 1rem;
    a {
      color: black;
      &:hover {
        text-decoration: underline;
      }
    }
    li {
      list-style-type: none;
    }
  }
`;

export const DesktopNavWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const DesktopNav = styled.nav`
  display: none;
  position: relative;
  justify-content: space-between;
  width: 100%;
  max-width: 75rem;
  ul.main {
    margin: 0;
    padding: 0;
    list-style-type: none;
    align-items: center;
    display: flex;
    position: relative;
    z-index: 1;
    padding-right: 2rem;
  }

  ul.main li {
    flex-grow: 0;
    padding-top: 1rem;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    text-align: center;
  }
  button.uppercase {
    outline: none;
    appearance: none;
    border: none;
    background-color: transparent;
    display: flex;
  }
  a.uppercase,
  button.uppercase {
    text-align: center;
    justify-content: center;
    flex-grow: 1;
    padding-right: 1rem;
    padding-left: 1rem;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    color: ${Globals.white};

    &:visited {
      color: ${Globals.white};
    }
  }

  ${Breakpoints.sm`
    display: flex;
    width: 100%;
    flex-shrink: 0;
  `}
`;

export const Nav = styled.nav`
  height: 100%;
  min-width: 15rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  > ul.main {
    width: 100%;
  }
  a {
    color: ${Globals.grayscale_u1};
    text-decoration: none;
    &:visited {
      color: ${Globals.grayscale_u1};
    }
  }
`;

export const Logo = styled.div`
  img {
    width: 8rem;
  }
  ${Breakpoints.sm`
    width: 10rem;  
`}
`;

export const BottomWrap = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
`;

export const Bottom = styled.div``;
