import styled from '@emotion/styled';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import TextField from '@material-ui/core/TextField';
import React, { Component } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { Globals } from '../styles/global';
import { withStyles, Button } from '@material-ui/core';

const url =
  '//mc.us20.list-manage.com/signup-form/subscribe?u=dd20d7522c6104e2be2b4e8d5&amp;id=0012a3e1f0';

const StyledInput = withStyles({
  root: {
    '& .MuiFormLabel-root': {
      color: 'white',
    },
    '& .MuiOutlinedInput-root': {
      color: 'white',
      '& fieldset, & fieldset:hover': {
        borderColor: 'white',
        borderWidth: '0.125rem',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(255,255,255,0.9)',
      },
    },
  },
})(TextField);

const CustomForm = ({
  status,
  message,
  onValidated,
}: {
  status: string;
  message: string;
  onValidated: (formData: {
    EMAIL: string;
    LNAME: string;
    FNAME: string;
    b_dd20d7522c6104e2be2b4e8d5_79993: string;
  }) => void;
}) => {
  let EMAIL: { value: string };
  let FNAME: { value: string };
  let LNAME: { value: string };
  let b_dd20d7522c6104e2be2b4e8d5_79993: { value: string } | null;
  const submit = () => {
    return (
      EMAIL &&
      FNAME &&
      LNAME &&
      b_dd20d7522c6104e2be2b4e8d5_79993 &&
      EMAIL.value.indexOf('@') > -1 &&
      onValidated({
        EMAIL: EMAIL.value,
        LNAME: LNAME.value,
        FNAME: FNAME.value,
        b_dd20d7522c6104e2be2b4e8d5_79993:
          b_dd20d7522c6104e2be2b4e8d5_79993.value,
      })
    );
  };

  return (
    <Wrapper className="py-u5 row justify-center">
      <div className="text-center pr-b contain-row">
        <div className="col-xs-12 font-u2">
          <MailOutlineIcon
            style={{ color: 'white', height: '4rem', width: '4rem' }}
          />
          <h3 className="my-u1 font-u2">Join our newsletter.</h3>
        </div>
      </div>
      <div className="contain-row">
        <div className="col-xs-12 col-sm-4">
          <StyledInput
            id="mc-FNAME"
            className="w-full"
            label="First Name"
            type="text"
            name="FNAME"
            autoComplete="fname"
            margin="normal"
            variant="outlined"
            inputRef={node => (FNAME = node)}
          />
        </div>
        <div className="col-xs-12 col-sm-4">
          <StyledInput
            id="mc-LNAME"
            className="w-full"
            label="Last Name"
            type="text"
            name="LNAME"
            autoComplete="lname"
            margin="normal"
            variant="outlined"
            inputRef={node => (LNAME = node)}
          />
        </div>
        <div className="col-xs-12 col-sm-4">
          <StyledInput
            id="mc-EMAIL"
            className="w-full"
            label="Email"
            type="email"
            name="EMAIL"
            autoComplete="email"
            margin="normal"
            variant="outlined"
            color="secondary"
            inputRef={node => (EMAIL = node)}
          />
          <input
            type="text"
            name="b_dd20d7522c6104e2be2b4e8d5_79993"
            tabIndex={-1}
            ref={node => (b_dd20d7522c6104e2be2b4e8d5_79993 = node)}
            hidden
          />
        </div>
        {status === 'sending' && <div>sending...</div>}
        {status === 'error' && (
          <div dangerouslySetInnerHTML={{ __html: message }} />
        )}
        {status === 'success' && (
          <div dangerouslySetInnerHTML={{ __html: message }} />
        )}

        <div className="col-xs-12 justify-center mt-u2">
          <button onClick={submit} className="button outline">
            Subscribe
          </button>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: ${Globals.purple};
  color: ${Globals.white};
`;

export default class Newsletter extends Component {
  render() {
    return (
      <MailchimpSubscribe
        url={url}
        render={({
          subscribe,
          status,
          message,
        }: {
          subscribe: (formData: FormData) => void;
          status: string;
          message: string;
        }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={(formData: FormData) => subscribe(formData)}
          />
        )}
      />
    );
  }
}
const form = () => <form></form>;
