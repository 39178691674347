import React, { useEffect, useCallback } from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'gatsby';
import Paper from '@material-ui/core/Paper';

type Option = {
  title: string;
  query: string;
  url: string;
};

export const getOptions = (query: string): Option[] => {
  const index =
    typeof window !== 'undefined' && window.__FLEXSEARCH__
      ? window.__FLEXSEARCH__.en.index
      : undefined;
  const store =
    typeof window !== 'undefined' && window.__FLEXSEARCH__
      ? window.__FLEXSEARCH__.en.store
      : undefined;

  if (!query || !index || !store) {
    return [];
  } else {
    let results = [];
    // search the indexed fields
    Object.keys(index).forEach(idx => {
      results.push(...index[idx].values.search(query)); // more search options at https://github.com/nextapps-de/flexsearch#index.search
    });

    // find the unique ids of the nodes
    results = Array.from(new Set(results));

    // return the corresponding nodes in the store
    const nodes = store
      .filter(node => (results.includes(node.id) ? node : null))
      .map(node => {
        const keys = Object.keys(node.node);
        let idx = -1;
        for (const [index, el] of keys.entries()) {
          if (idx > -1) break;
          if (el.includes('.title')) idx = index;
        }
        const title = node.node[keys[idx]];
        return idx > -1
          ? {
              title,
              url: node.node.url,
              query,
            }
          : undefined;
      })
      .filter(node => !!node);

    return nodes;
  }
};

export const OptionDropdown = ({
  onClose,
  options,
}: {
  onClose: () => void;
  options: Option[];
}) => {
  const escFunction = useCallback(event => {
    if (event.keyCode === 27) {
      //Do whatever when esc is pressed
      return onClose();
    }
  }, []);

  const clickFunction = useCallback(event => {
    return onClose();
  }, []);

  useEffect(() => {
    window.addEventListener('click', clickFunction, false);
    window.addEventListener('keydown', escFunction, false);
    return () => {
      window.removeEventListener('click', clickFunction);
      window.removeEventListener('keydown', escFunction);
    };
  }, []);
  return (
    <Paper
      style={{
        position: 'absolute',
        top: '100%',
        left: 0,
        right: 0,
        height: 'auto',
        zIndex: 2,
      }}
    >
      <ul className="autocomplete">
        {options.map((option, index) => {
          return option.title ? (
            <li key={index}>
              <Link to={option.url}>
                <ListItemText primary={option.title} />
              </Link>
            </li>
          ) : null;
        })}
      </ul>
    </Paper>
  );
};
