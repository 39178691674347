import '../styles/styles.css';

import * as React from 'react';
import styled from '@emotion/styled';

import Footer from '../components/footer';
import Header from '../components/header';
import Newsletter from '../components/newsletter';
import { Globals } from '../styles/global';

type HeaderColor = 'primary' | 'secondary';
const MainLayout: React.SFC = props => {
  const children = props.children as React.ReactElement;
  let headerColor: HeaderColor = 'primary';
  if (typeof children.props !== 'undefined') {
    if (children.props.contentSubtitle === 'Adopting') {
      headerColor = 'secondary';
    }
  }
  return (
    <PageWrap headerColor={headerColor}>
      <Top>
        <Header />
        {children}
      </Top>
      <Bottom>
        <Newsletter />
        <Footer />
      </Bottom>
    </PageWrap>
  );
};

export default MainLayout;

const PageWrap = styled.div<{ headerColor: HeaderColor }>`
  --header-color: ${props =>
    props.headerColor === 'secondary' ? Globals.red : Globals.orange};
  &.header-color-background,
  & .header-color-background {
    background-color: ${props =>
      props.headerColor === 'secondary' ? Globals.red : Globals.orange};
  }
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-grow: 1;

  --orange-color: ${Globals.orange};
  --blue-color: ${Globals.blue};
  --red-color: ${Globals.red};
  --white-color: ${Globals.white};

  .font-orange {
    color: ${Globals.orange};
  }
  .font-white {
    color: ${Globals.white};
  }
  .font-blue {
    color: ${Globals.blue};
  }
  .font-red {
    color: ${Globals.red};
  }
  .b-orange {
    background-color: ${Globals.orange};
  }
  .b-white {
    background-color: ${Globals.white};
  }
  .b-blue {
    background-color: ${Globals.blue};
  }
  .b-red {
    background-color: ${Globals.red};
  }
`;
const Top = styled.div`
  flex-grow: 1;
  max-width: 100%;
  max-width: 100vw;
`;
const Bottom = styled.div`
  max-width: 100%;
  max-width: 100vw;
`;
